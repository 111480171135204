html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0 0 40px;
  background-color: #18181b !important;
}

html {
  font-family: "Noto Sans", Helvetica, Arial, Lucida, sans-serif;
}

/* General */

.vh-50 {
  height: 100vh;
}

/* Weights */
.font-weight-thin {
  font-weight: 100 !important;
}

.font-weight-extralight,
.font-weight-extra-light {
  font-weight: 200 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular,
.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold,
.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extrabold,
.font-weight-extra-bold {
  font-weight: 800 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

/* Navbar */

.header {
  background-color: transparent;
}

.header-bg {
  background-color: rgba(0, 0, 0, 0.9);
}

.nav-link {
  font-family: "Noto Sans", Helvetica, Arial, Lucida, sans-serif !important;
  color: white !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.nav--container {
  display: flex;
  justify-content: space-between;
}

.nav--bar {
  font-family: "Noto Sans", Helvetica, Arial, Lucida, sans-serif;
  color: white !important;
}

.nav--icon {
  height: 35px;
  padding-right: 8px;
  padding-left: 15px;
}

.nav--item {
  color: white;
  font-weight: bold;
  text-decoration: none;
  transition: 0.2s;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
}

.nav--bar.title {
  justify-content: end;
  padding-right: 35px;
  font-style: italic;
  font-size: 2rem;
}

.nav--item:hover {
  text-decoration: underline;
}

.nav--button {
  color: white;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: block;
  font-size: 35px;
  background-color: transparent;
  text-decoration: none;
  border: none;
  display: flex;
  border-radius: 20px;
}

.nav--button:hover ul {
  color: #21222a;
  background-color: white;
  transition: 0.3s ease-in-out all;
}

.nav--dropdown {
  display: flex;
  padding: 0;
}
.navigation--menu .nav--link {
  list-style-type: none;
  margin: 0 1rem;
}
.navigation--menu .nav--link a {
  text-decoration: none;
  display: block;
  width: 100%;
  color: white;
}

.navigation--menu,
.nav--button {
  margin-left: auto;
}

@media screen and (min-width: 450px) {
  .nav--button,
  .navigation--menu {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .nav--item,
  .nav--bar.title {
    display: none;
  }

  .nav--dropdown {
    display: none;
  }

  .nav--dropdown .nav--link {
    left: 0;
    z-index: 1;
    width: 100%;
    position: relative;
  }

  .navigation--menu.expanded {
    height: 230px;
  }

  .navigation--menu.expanded .nav--dropdown {
    position: absolute;
    top: 75px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: 230px;
    background-color: white;
    border-top: 1px solid black;
    display: block;
  }

  .navigation--menu .nav--link {
    text-align: center;
    margin: 0;
  }
  .navigation--menu .nav--link a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }

  .fixed-top {
    position: sticky !important;
  }

  .header {
    background-color: rgba(0, 0, 0, 0.9);
  }
}

@media screen and (max-width: 500px) {
  .vh-50 {
    height: 40vh;
  }
}

@media screen and (max-width: 375px) {
  .vh-50 {
    height: 50vh;
  }
}

/* Main Container */

.main--container {
  height: auto;
  color: white;
  z-index: 0;
  width: 100vw;
  max-width: 100%;
}

.video--container {
  overflow: hidden;
  z-index: 0;
  background-color: #18181b;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
}

.background--video {
  min-width: 100%;
  min-height: 100%;
  opacity: 0.5;
}

.content--row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  min-height: 675px;
}

.info--container {
  padding: 20px;
  margin-left: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  border-width: 1px;
  border-color: #555555;
  padding-top: 6%;
  padding-right: 10px;
  padding-bottom: 7%;
  padding-left: 10px;
  box-shadow: 0px 20px 18px -6px rgba(0, 0, 0, 0.42);
  border: 0 solid #333;
  font-size: 14px;
  margin-right: 15px;
}

.feature-icon {
  width: 4rem;
  height: 4rem;
  border-radius: 0.75rem;
}

.icon-square {
  width: 3rem;
  height: 3rem;
  border-radius: 0.75rem;
}

.text-shadow-1 {
  text-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
}
.text-shadow-2 {
  text-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}
.text-shadow-3 {
  text-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.25);
}

.card-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.feature-icon-small {
  width: 3rem;
  height: 3rem;
}

@media screen and (max-width: 500px) {
  .video--container {
    height: auto;
    display: flex;
  }

  .background--video {
    height: auto;
    max-width: none;
  }
}

@media screen and (max-width: 960px) {
  .content--row {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}

.champion--container {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-width: 1px;
  border-color: #555555;
  padding-right: 10px;
  padding-left: 10px;
  box-shadow: 0px 20px 18px -6px rgba(0, 0, 0, 0.42);
  border: 0 solid #333;
}

.champion--container button {
  background: transparent;
  border: transparent;
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
}

.left--button,
.right--button {
  color: white;
}

.left--button:hover {
  color: #057eff;
  transform: translateX(-1px);
}

.right--button:hover {
  color: #057eff;
  transform: translateX(+1px);
}

.champion--list {
  margin-left: 20px;
}

.champion--list li {
  padding-top: 3px;
  padding-bottom: 3px;
  list-style-type: none;
  font-size: 13px;
  font-family: "Noto Sans", Helvetica, Arial, Lucida, sans-serif;
}

.fb {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.fb--container {
  width: 340px;
}

/* Engines Nav Page */

.engine--main-container {
  display: flex;
  padding-top: 10px;
  padding-left: 15px;
  flex-direction: column;
}

.engine--seletion-container {
  color: white;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.engine--seletion-container.two {
  margin-top: 15px;
  margin-bottom: 20px;
}

.engine--seletion-container ul {
  display: grid;
  gap: 10px;
}

.engine--seletion-container a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}

.engine--seletion-container a:hover {
  text-decoration: underline;
}

.logos--container {
  width: 182px;
  transition: 0.2s;
}

.logos--container:hover {
  transform: scale(1.1);
}

.logo {
  width: 182px;
}

.logo--two {
  border-radius: 50%;
  height: 150px;
}

@media screen and (min-width: 960px) {
  .engine--main-container {
    margin-left: calc(50% - 240px);
  }
}

@media screen and (max-width: 450px) {
  .logo,
  .logo--two,
  .logos--container {
    width: 120px;
  }

  .logo--two {
    height: 120px;
  }
}

/* Engine Pages */

.engine--container {
  margin-left: 1rem;
  color: white;
}

@media screen and (min-width: 960px) {
  .engine--container {
    margin-left: 5rem;
  }
}

.engine-h1 {
  color: white;
}

.img-gallery {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.img-gallery img {
  float: left;
  width: 250px;
  height: 250px;
  object-fit: cover;
  justify-self: center;
  margin: auto;
  object-position: center center;
}

/*Contact */

.contact {
  margin-top: 62px;
}

.contact--container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  color: white;
  margin: 20px 20px;
  text-align: center;
  justify-content: space-evenly;
}

.contact--info {
  width: 275px;
  background-color: white;
  border-radius: 55px;
  padding-left: 15px;
  padding-right: 15px;
  color: #18181b;
}

.contact--info h3 {
  font-size: 30px;
}

@media screen and (max-width: 850px) {
  .contact--container,
  .contact--directions {
    flex-direction: column;
    align-items: center;
  }

  .img-gallery {
    display: flex;
    gap: 30px;
    flex-direction: column;
  }
}

.contact--directions {
  background-color: white;
  align-items: center;
  display: flex;
  gap: 15px;
}

.contact--directions div {
  margin-left: 15px;
  margin-right: 15px;
}

/* Footer */

.footer {
  background-color: #057eff;
  color: white;
  height: 40px;
  justify-content: center;
  display: grid;
  align-items: center;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 40px;
  overflow: hidden;
}

.footer p {
  margin-top: 0px;
  margin-bottom: 0px;
}
